import { makeStyles } from '@material-ui/core'

import { theme } from '../../../../../../../../theme'

const useStyles = makeStyles(() => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      gap: '20px',
      backgroundColor: palette.background.paper,
      [breakpoints.down('md')]: {
        padding: '8px',
        gap: '12px'
      },
      [breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    contentContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      [breakpoints.down('sm')]: {
        gap: '4px'
      }
    },
    imageCarouselContainer: {
      display: 'flex'
    },
    generalInfoContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    offerChipsContainer: {
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    topSectionContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    experienceLocationScheduleSection: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  }
})

export const usePractitionerListingCardStyles = () => {
  const classes = useStyles()
  return { classes }
}
