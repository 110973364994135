import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      gap: '8px'
    },
    title: {
      fontSize: '16px',
      fontWeight: 500,
      color: palette.blue['800'],
      padding: 0,
      letterSpacing: 0,
      [breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    },
    description: {
      fontSize: '14px',
      color: palette.blue['300'],
      padding: 0,
      letterSpacing: 0,

      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 3,
      lineHeight: '20px',
      maxHeight: '60px',
      wordWrap: 'wrap',

      [breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    }
  }
})

export const useDescriptionSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
