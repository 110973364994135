import { Box } from '@material-ui/core'
import { memo, useState } from 'react'

import {
  ExperienceLocationSection,
  GeneralInfoSection,
  ImageCarousel,
  ScheduleSection
} from './components'
import { usePractitionerListingCardStyles } from './hooks'

import { OfferChipsSection } from '../../../PractitionerListing/components'

export const PractitionerListingCard = memo(({ listing, history }) => {
  const { classes } = usePractitionerListingCardStyles()

  const {
    id,
    clinicName,
    practitionerName,
    abbreviatedPractitionerName,
    practitionerPhoto,
    averageRating,
    reviewsCount,
    schedule,
    location,
    experience,
    photos,
    offers,
    state
  } = listing

  const clickHandler = () => {
    history.push(`/l/${id}`)
  }

  return (
    <Box className={classes.container} onClick={clickHandler}>
      <Box className={classes.imageCarouselContainer}>
        <ImageCarousel images={photos} />
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.topSectionContainer}>
          <Box className={classes.generalInfoContainer}>
            <GeneralInfoSection
              profilePhotoUrl={practitionerPhoto}
              clinicName={clinicName}
              practitionerName={practitionerName}
              abbreviatedPractitionerName={abbreviatedPractitionerName}
              averageRating={averageRating}
              reviewsCount={reviewsCount}
              state={state}
            />
            <Box className={classes.offerChipsContainer}>
              <OfferChipsSection offers={offers} />
            </Box>
          </Box>
          {/* <FavoritesButton
              isActive={isFavouritesButtonActive}
              onClick={() => {
                onFavouritesButtonClick(id)
                setIsFavouriteButtonActive((prevValue) => !prevValue)
              }}
            /> */}
        </Box>
        <Box className={classes.experienceLocationScheduleSection}>
          <ExperienceLocationSection
            experience={experience}
            location={location.name.address.split(', ')[1]}
          />
          <ScheduleSection schedule={schedule} />
        </Box>
      </Box>
    </Box>
  )
})
