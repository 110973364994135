import { Box, Typography } from '@material-ui/core'

import { useNoSchedulePlaceholderStyles } from './hooks'

import NO_SCHEDULE_PICTURE from '../../../../../../../../../../../assets/no-schedule-banner.svg'

export const NoSchedulePlaceholder = () => {
  const { classes } = useNoSchedulePlaceholderStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>
        Working hours vary. Tap Book a consultation to see availability.
      </Typography>
      <img className={classes.image} src={NO_SCHEDULE_PICTURE} />
    </Box>
  )
}
