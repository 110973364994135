import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => {
  const color = ({ isActive, isFavourite }) => {
    if (isFavourite) {
      return isActive ? '#d74d58' : '#FF606D'
    } else {
      return isActive ? '#006D77' : '#006D77'
    }
  }

  return {
    topContainer: {
      backgroundColor: ({ isActive, isFavourite }) => color({ isActive, isFavourite }),
      width: '30px',
      height: '30px',
      borderRadius: '15px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      fontSize: '14px',
      fontWeight: 'bold',
      cursor: 'pointer',
      zIndex: 100
    },
    bottomContainer: {
      content: '""',
      position: 'absolute',
      bottom: '-10px',
      width: '0',
      height: '0',
      borderLeft: '13px solid transparent',
      borderRight: '13px solid transparent',
      borderTop: ({ isActive, isFavourite }) => `17px solid ${color({ isActive, isFavourite })}`,
      zIndex: 50
    },
    circle: {
      backgroundColor: 'white',
      width: '9px',
      height: '9px',
      borderRadius: '50%',
      position: 'absolute'
    }
  }
})

export const useMarkerStyles = ({ isActive, isFavourite }) => {
  const classes = useStyles({ isActive, isFavourite })
  return { classes }
}
