import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0
    },
    text: {
      fontSize: '16px',
      color: ({ isActive }) => (isActive ? palette.blue['800'] : palette.blue['300']),
      padding: 0,
      letterSpacing: 0,
      [breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    },
    timeContainer: {
      display: 'flex',
      textAlign: 'left',
      left: 0
    },
    dayContainer: {
      display: 'flex',
      gap: '4px',
      alignItems: 'center'
    },
    activePipe: {
      width: '2px',
      height: '16px',
      borderRadius: '2px',
      backgroundColor: palette.primary.main
    }
  }
})

export const useScheduleRecordStyles = ({ isActive }) => {
  const classes = useStyles({ isActive })
  return { classes }
}
