import { faBriefcase, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { Box, Typography } from '@material-ui/core'

import { useExperienceLocationSectionStyles } from './hooks'

import { Icon } from '../../../../../../../Icon'

export const ExperienceLocationSection = ({ experience, location }) => {
  const { classes } = useExperienceLocationSectionStyles()

  return (
    <Box className={classes.container}>
      {experience && (
        <Box className={classes.experienceContainer}>
          <Icon icon={faBriefcase} className={classes.briefcaseIcon} />
          <Typography className={classes.text}>{experience} years</Typography>
        </Box>
      )}
      <Box className={classes.textContainer}>
        <Icon icon={faLocationDot} className={classes.locationDotIcon} />
        <Typography className={classes.text}>{location}</Typography>
      </Box>
    </Box>
  )
}
