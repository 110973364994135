import { makeStyles } from '@material-ui/core'

import { theme } from '../../../../../../../../../../theme'

const useStyles = makeStyles(() => {
  const { breakpoints } = theme

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    chipLabel: {
      fontSize: '13px',
      lineHeight: '18px',
      padding: 0,
      letterSpacing: 0,
      [breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '16px'
      }
    }
  }
})

export const useOfferChipsSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
