import { Typography, Box } from '@material-ui/core'

import { useDescriptionSectionStyles } from './hooks'

export const DescriptionSection = ({ descriptionTitle, descriptionText, type }) => {
  const { classes } = useDescriptionSectionStyles()

  return (
    <Box className={classes.container}>
      {type === 'listing' && <Typography className={classes.title}>{descriptionTitle}</Typography>}
      <Typography className={classes.description}>{descriptionText}</Typography>
    </Box>
  )
}
