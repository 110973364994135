import { makeStyles } from '@material-ui/core'

import { theme } from '../../../../../../../../../../theme'

const useStyles = makeStyles(() => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px'
    },
    textContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    experienceContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    text: {
      fontSize: '16px',
      padding: 0,
      letterSpacing: 0,
      color: palette.blue['300']
    },
    locationDotIcon: {
      color: palette.blue['300'],
      width: '12px',
      height: '16px'
    },
    briefcaseIcon: {
      color: palette.blue['300'],
      width: '16px',
      height: '16px'
    }
  }
})

export const useExperienceLocationSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
