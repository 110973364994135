// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited. The webpack bundler fails to import css files
// from node_modules.
import '../../../../../shared/image-gallery.css'

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Box } from '@material-ui/core'
import classNames from 'classnames'
import { useState } from 'react'
import ReactImageGallery from 'react-image-gallery'

import { useImageCarouselSectionStyles } from './hooks'

import { Icon } from '../../../../../Icon'

export const ImageCarouselSection = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const isFirstSlide = currentIndex === 0
  const isLastSlide = currentIndex === images.length - 1
  const { classes } = useImageCarouselSectionStyles({ isFirstSlide, isLastSlide })

  const leftNavButtonJoinedClasses = classNames(classes.navButtonContainer, classes.leftNavButton)
  const rightNavButtonJoinedClasses = classNames(classes.navButtonContainer, classes.rightNavButton)

  const renderImage = (image) => {
    return (
      <Box className={classes.imageWrapper}>
        <img src={image} className={classes.image} />
      </Box>
    )
  }

  const renderLeftNavButton = (clickHandler) => {
    return (
      <Box
        className={leftNavButtonJoinedClasses}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          clickHandler()
        }}
      >
        <Icon icon={faAngleLeft} className={classes.navButtonIcon} />
      </Box>
    )
  }

  const renderRightNavButton = (clickHandler) => {
    return (
      <Box
        className={rightNavButtonJoinedClasses}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          clickHandler()
        }}
      >
        <Icon icon={faAngleRight} className={classes.navButtonIcon} />
      </Box>
    )
  }

  const slideHandler = (index) => {
    setCurrentIndex(index)
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.carouselContainer}>
        <ReactImageGallery
          items={images}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
          showNav
          showBullets
          lazyLoad
          renderItem={renderImage}
          renderLeftNav={renderLeftNavButton}
          renderRightNav={renderRightNavButton}
          onSlide={slideHandler}
          infinite={false}
        />
      </Box>
    </Box>
  )
}
