import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      width: '260px',
      [breakpoints.down('sm')]: {
        gap: '12px',
        width: '100%'
      }
    },
    header: {
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 500,
      color: palette.blue['800'],
      padding: 0,
      letterSpacing: 0,
      [breakpoints.down('sm')]: {
        fontSize: '16px'
      }
    }
  }
})

export const useScheduleSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
