import { makeStyles } from '@material-ui/core/styles'

import { theme } from '../../../../../../../../theme'

const useStyles = makeStyles(() => {
  const { palette } = theme

  return {
    addFavoritesButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '4px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: palette.primary.light
      },
      '&:focus': {
        backgroundColor: palette.secondary.dark
      }
    },
    addFavoritesButton: {
      color: palette.primary.main
    }
  }
})

export const useFavoritesButtonStyles = () => {
  const classes = useStyles()
  return { classes }
}
