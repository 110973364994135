import { makeStyles } from '@material-ui/core'

import { theme } from '../../../../../../../../../../theme'

const useStyles = makeStyles(() => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      gap: '4px',
      alignItems: 'center'
    },
    additionalTextContainer: {
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    text: {
      fontFamily: 'Inter',
      fontSize: '14px',
      color: palette.blue['800'],
      padding: 0,
      letterSpacing: 0
    },
    circleIcon: {
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      backgroundColor: palette.text.disabled
    }
  }
})

export const useScheduleSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
