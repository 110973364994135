import { Typography, Box } from '@material-ui/core'
import { faLocationDot, faLocationArrow } from '@fortawesome/free-solid-svg-icons'

import { useLocationSectionStyles } from './hooks'

import { Icon } from '../../../../../../../../../Icon'

export const LocationSection = ({ address, distance }) => {
  const { classes } = useLocationSectionStyles()

  if (address || distance) {
    return (
      <Box className={classes.container}>
        {address && (
          <Box className={classes.addressContainer}>
            <Icon icon={faLocationDot} className={classes.locationDotIcon} />
            <Typography className={classes.address}>{address}</Typography>
          </Box>
        )}
        {distance && (
          <Box className={classes.distanceContainer}>
            <Icon icon={faLocationArrow} className={classes.locationArrowIcon} />
            <Typography className={classes.distance}>{distance}</Typography>
          </Box>
        )}
      </Box>
    )
  }

  return null
}
