import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { breakpoints } = theme

  return {
    container: {
      width: '186px',
      height: '42px',
      [breakpoints.down('sm')]: {
        width: '100%'
      }
    }
  }
})

export const useBookConsultationButtonStyles = () => {
  const classes = useStyles()
  return { classes }
}
