import { Box, Typography } from '@material-ui/core'

import { useScheduleSectionStyles } from './hooks'

import { getCurrentDayName } from '../../../../../../../../../util/new/date'

const checkOpenStatus = (timeRange) => {
  if (timeRange.from !== undefined && timeRange.to !== undefined) {
    const now = new Date()
    const currentTime = now.getHours() * 60 + now.getMinutes() // Convert current time to minutes since midnight

    const [startHours, startMinutes] = timeRange.from.split(':').map(Number)
    const startTime = startHours * 60 + startMinutes

    const [endHours, endMinutes] = timeRange.to.split(':').map(Number)
    const endTime = endHours * 60 + endMinutes

    if (startTime < endTime) {
      // Normal case: start time is before end time
      if (currentTime >= startTime && currentTime <= endTime) {
        return 'open'
      }
    } else {
      // Overnight case: end time is after midnight
      if (currentTime >= startTime || currentTime <= endTime) {
        return 'open'
      }
    }
  }

  return 'closed'
}

const getCurrentStatus = (schedule) => {
  if (schedule) {
    const currentDay = getCurrentDayName()

    const currentDaySchedule = schedule.find((entry) => entry.day === currentDay)

    if (currentDaySchedule) {
      const timeRange = {
        from: currentDaySchedule.from,
        to: currentDaySchedule.to
      }

      const statusText = checkOpenStatus(timeRange) === 'open' ? 'Open' : 'Closed'
      const additionalStatusText = getStatusMessage(schedule)

      return { statusText, additionalStatusText }
    }

    return 'Closed'
  }

  return 'Closed'
}

export const ScheduleSection = ({ schedule }) => {
  const { classes } = useScheduleSectionStyles()
  const { statusText, additionalStatusText } = getCurrentStatus(schedule)

  if (!schedule) {
    return null
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>{statusText}</Typography>
      <Box className={classes.additionalTextContainer}>
        <Box className={classes.circleIcon} />
        <Typography className={classes.text}>{additionalStatusText}</Typography>
      </Box>
    </Box>
  )
}
