import { Box, Typography } from '@material-ui/core'
import moment from 'moment'

import { useScheduleRecordStyles } from './hooks'

import { capitalizeFirstLetter } from '../../../../../../../../../../../util/new/string'

export const ScheduleRecord = ({ record, isActive }) => {
  const { classes } = useScheduleRecordStyles({ isActive })
  const { day, from, to } = record
  const isUnavailable = !from || !to

  const formatTime = (time) => moment(time, 'HH:mm').format('h:mm a')

  return (
    <li className={classes.container}>
      <Box className={classes.dayContainer}>
        {isActive && <div className={classes.activePipe} />}
        <Typography className={classes.text}>{capitalizeFirstLetter(day)}</Typography>
      </Box>
      <Box className={classes.timeContainer}>
        <Typography className={classes.text}>
          {isUnavailable ? 'unavailable' : `${formatTime(from)} - ${formatTime(to)}`}
        </Typography>
      </Box>
    </li>
  )
}
