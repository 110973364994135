import { Box, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'

import { useTreatmentChipsSectionStyles } from './hooks'

import { Button } from '../../../../../Button'
import { Chip } from '../../../../../Chip'
import { specializations } from '../../../../../utils/practitionerListing'

const getDisplayValue = (searchValue) => {
  const specialization = specializations.find(
    (specialization) => specialization.value === searchValue
  )
  return specialization.displayValue
}

export const TreatmentChipsSection = ({ treatments }) => {
  const [renderedChips, setRenderedChips] = useState()
  const [showMoreButtonVisible, setShowMoreButtonVisible] = useState(false)
  const { classes } = useTreatmentChipsSectionStyles()

  useEffect(() => {
    const chips = treatments
      .slice(0, 3)
      .map((treatment) => (
        <Chip
          label={getDisplayValue(treatment)}
          type="treatment"
          className={classes.chip}
          key={treatment}
        />
      ))

    setRenderedChips(chips)
  }, [])

  useEffect(() => {
    const shouldRenderShowMoreButton = !(renderedChips?.length > 3)
    setShowMoreButtonVisible(shouldRenderShowMoreButton)
  }, [renderedChips])

  const showMoreButtonClickHandler = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let chips

    if (showMoreButtonVisible) {
      chips = treatments.map((treatment) => (
        <Chip label={getDisplayValue(treatment)} type="treatment" className={classes.chip} />
      ))
    } else {
      chips = treatments
        .slice(0, 3)
        .map((treatment) => (
          <Chip
            label={getDisplayValue(treatment)}
            type="treatment"
            className={classes.chip}
            key={treatment}
          />
        ))
    }

    setRenderedChips(chips)
  }

  const renderShowMoreButton = () => {
    const numOfHiddenChips = treatments.length - 3
    return (
      <Button
        uiType="tertiary"
        className={classes.showMoreButton}
        onClick={showMoreButtonClickHandler}
      >
        {showMoreButtonVisible ? `Show ${numOfHiddenChips} more` : 'Show less'}
      </Button>
    )
  }

  const shouldRenderButton = treatments.length > 3

  if (treatments.length > 0) {
    return (
      <Box>
        <Typography variant="caption">Specialisations</Typography>
        <Box className={classes.container}>
          {renderedChips}
          {shouldRenderButton && renderShowMoreButton()}
        </Box>
      </Box>
    )
  }

  return null
}
