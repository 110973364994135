import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { breakpoints, palette } = theme

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '8px'
    },
    chipLabel: {
      fontSize: '13px',
      lineHeight: '18px',
      padding: 0,
      letterSpacing: 0,
      [breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '16px'
      }
    },
    buttonsContainer: {
      bottom: 0,
      left: 0,
      width: '100%',
      display: 'flex',
      gap: '16px',
      paddingTop: '20px',
      paddingBottom: '20px',
      paddingLeft: '24px',
      paddingRight: '24px',
      backgroundColor: palette.background.paper
    },
    button: {
      flex: 1,
      height: '42px'
    }
  }
})

export const useOfferChipsSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
