import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
      width: '100%'
    },
    text: {
      fontSize: '14px',
      color: palette.blue['800']
    },
    image: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    }
  }
})

export const useNoSchedulePlaceholderStyles = () => {
  const classes = useStyles()
  return { classes }
}
