import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Avatar, Box, Typography } from '@material-ui/core'

import { useGeneralInfoSectionStyles } from './hooks'

import { Icon } from '../../../../../Icon'

export const GeneralInfoSection = ({
  profilePhotoUrl,
  clinicName,
  practitionerName,
  abbreviatedPractitionerName,
  averageRating,
  reviewsCount
}) => {
  const { classes } = useGeneralInfoSectionStyles()

  return (
    <Box className={classes.container}>
      {profilePhotoUrl ? (
        <Avatar src={profilePhotoUrl} className={classes.avatarContainer} />
      ) : (
        <Avatar className={classes.avatarContainer}>{abbreviatedPractitionerName}</Avatar>
      )}
      <Box className={classes.generalInfoContainer}>
        <Box>
          {clinicName && <Typography className={classes.clinicTitle}>{clinicName}</Typography>}
          <Typography className={classes.practitionerName}>{practitionerName}</Typography>
        </Box>
        {reviewsCount > 0 && (
          <Box className={classes.outerReviewsContainer}>
            <Box className={classes.innerReviewsContainer}>
              <Icon icon={faStar} className={classes.starIcon} />
              <Typography className={classes.averageRating}>{averageRating.toFixed(1)}</Typography>
              <Typography
                className={classes.reviewsCount}
              >{`(${reviewsCount} reviews)`}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
