import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    toggleContainer: {
      display: 'none',
      [breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }
    },
    downAngleIcon: {
      width: '18px',
      height: '18px',
      color: palette.grey['400'],
      transition: 'transform 0.3s ease',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    downAngleIconRotated: {
      transform: 'rotate(180deg)'
    },
    openClosedContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    text: {
      fontFamily: 'Inter',
      fontSize: '14px',
      color: palette.blue['800'],
      padding: 0,
      letterSpacing: 0
    },
    desktopScheduleContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      margin: 0,
      [breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    mobileScheduleContainer: {
      display: 'none',
      [breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        margin: 0
      }
    }
  }
})

export const useScheduleStyles = () => {
  const classes = useStyles()
  return { classes }
}
