import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%'
  }
})

export const useDetailedInfoSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
