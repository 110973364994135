import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo } from 'react'

import { useMarkerStyles } from './hooks'

export const Marker = memo(({ listing, isActive, isFavourite }) => {
  const { classes } = useMarkerStyles({ isActive, isFavourite })

  if (listing) {
    return (
      <div className={classes.topContainer}>
        {isFavourite ? (
          <FontAwesomeIcon icon={faHeart} color="#fff" />
        ) : listing.reviewsCount > 0 ? (
          <span>{listing.averageRating.toFixed(1)}</span>
        ) : (
          <div className={classes.circle} />
        )}
        <div className={classes.bottomContainer}></div>
      </div>
    )
  }

  return (
    <div className={classes.topContainer}>
      <div className={classes.circle} />
      <div className={classes.bottomContainer}></div>
    </div>
  )
})
