import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'

import { useOfferChipsSectionStyles } from './hooks'

import { faNotesMedical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../../../../ducks/user.duck'
import { Button } from '../../../../../Button'
import { Chip } from '../../../../../Chip'

export const OfferChipsSection = ({ offers }) => {
  const { classes } = useOfferChipsSectionStyles()
  const currentUser = useSelector(selectCurrentUser)
  const currentUserId = currentUser?.id?.uuid
  const showComplications = currentUserId === '66de35d1-6f45-4d26-9cf2-198fa25ac00f'

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const renderOfferChips = () => {
    const renderedOfferChips = []

    if (showComplications) {
      renderedOfferChips.push(
        <Chip
          key="complications"
          label={
            <Box display={'flex'} justifyContent={'center'} alignContent={'center'}>
              <FontAwesomeIcon icon={faNotesMedical} style={{ marginRight: 4 }} />
              <Typography className={classes.chipLabel}>24/7 Support</Typography>
            </Box>
          }
          type="support"
          onClick={(value, e) => {
            e.stopPropagation()
            setIsDialogOpen(true)
          }}
        />
      )
    }

    if (offers.onlineConsultation) {
      renderedOfferChips.push(
        <Chip
          key="consultation"
          label={<Typography className={classes.chipLabel}>Video Consultation</Typography>}
          type="consultation"
        />
      )
    }

    if (offers.hundredCredit) {
      renderedOfferChips.push(
        <Chip
          key="credit"
          label={<Typography className={classes.chipLabel}>$100 Credit</Typography>}
          type="primary"
        />
      )
    }

    return renderedOfferChips
  }

  if (offers.onlineConsultation || offers.hundredCredit || showComplications) {
    return (
      <>
        <Box className={classes.container}>{renderOfferChips()}</Box>

        {showComplications && (
          <Dialog key="complications-popup" open={isDialogOpen} maxWidth="sm">
            <DialogTitle>24/7 Support</DialogTitle>
            <DialogContent>
              This practitioner is enrolled in 24/7 support for management of complications and has
              access to the latest safety protocols.
            </DialogContent>
            <DialogActions>
              <Box className={classes.buttonsContainer}>
                <Button
                  key="complications-close"
                  uiType="secondary"
                  className={classes.button}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsDialogOpen(false)
                  }}
                >
                  Close
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </>
    )
  }

  return null
}
