import { makeStyles } from '@material-ui/core/styles'

import { theme } from '../../../../../../../../../../theme'

const useStyles = makeStyles(() => {
  const { breakpoints, palette } = theme

  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    carouselContainer: {
      width: '225px',
      height: '225px',
      borderRadius: '4px',
      overflow: 'hidden',
      '&:hover $navButtonContainer': {
        opacity: 1,
        visibility: 'visible'
      },
      [breakpoints.down('sm')]: {
        width: '125px',
        height: '125px'
      }
    },
    image: {
      objectFit: 'cover',
      width: '225px',
      height: '225px',
      [breakpoints.down('sm')]: {
        width: '125px',
        height: '125px'
      }
    },
    navButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      opacity: 0,
      width: '40px',
      height: '40px',
      top: 'calc(50% - 20px)',
      position: 'absolute',
      zIndex: 2,
      backgroundColor: palette.common.white,
      color: palette.primary.main,
      visibility: 'hidden',
      transition: 'opacity 0.3s ease, visibility 0.3s ease',
      '&:hover': {
        backgroundColor: palette.primary.light
      }
    },
    navButtonIcon: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    leftNavButton: {
      display: ({ isFirstSlide }) => (isFirstSlide ? 'none' : 'flex'),
      left: '12px'
    },
    rightNavButton: {
      display: ({ isLastSlide }) => (isLastSlide ? 'none' : 'flex'),
      right: '12px'
    },
    bullet: {
      display: 'inline-block',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: theme.palette.grey[400],
      margin: '0 4px',
      cursor: 'pointer',
      '&.active': {
        backgroundColor: theme.palette.primary.main
      }
    }
  }
})

export const useImageCarouselStyles = ({ isFirstSlide, isLastSlide }) => {
  const classes = useStyles({ isFirstSlide, isLastSlide })
  return { classes }
}
