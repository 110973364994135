import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { Box, Typography } from '@material-ui/core'

import { useExperienceSectionStyles } from './hooks'

import { Icon } from '../../../../../../../../../Icon'

export const ExperienceSection = ({ experience }) => {
  const { classes } = useExperienceSectionStyles()

  return (
    <Box className={classes.container}>
      <Icon icon={faBriefcase} className={classes.briefcaseIcon} />
      <Typography className={classes.title}>{experience} years</Typography>
    </Box>
  )
}
