import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      [breakpoints.down('xs')]: {
        alignItems: 'start'
      }
    },
    avatarContainer: {
      width: '52px',
      height: '52px',
      fontSize: '20px',
      backgroundColor: palette.primary.main,
      [breakpoints.down('sm')]: {
        width: '46px',
        height: '46px'
      }
    },
    generalInfoContainer: {
      display: 'flex',
      [breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    outerReviewsContainer: {
      display: 'flex',
      alignItems: 'end',
      marginLeft: '16px',
      [breakpoints.down('xs')]: {
        marginLeft: 0
      }
    },
    innerReviewsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    starIcon: {
      color: palette.primary.main,
      width: '18px',
      height: '18px'
    },
    averageRating: {
      fontSize: '16px',
      color: palette.primary.main,
      [breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    },
    reviewsCount: {
      fontSize: '16px',
      color: palette.text.disabled,
      '&:hover': {
        color: palette.primary.main,
        cursor: 'pointer'
      },
      [breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    },
    clinicTitle: {
      padding: 0,
      fontSize: '16px',
      color: palette.blue['300'],
      letterSpacing: 0,
      [breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    },
    practitionerName: {
      padding: 0,
      fontSize: '24px',
      fontWeight: 500,
      color: palette.blue['800'],
      lineHeight: '29px',
      letterSpacing: 0,
      '&:hover': {
        color: palette.primary.main,
        cursor: 'pointer'
      },
      [breakpoints.down('sm')]: {
        fontSize: '20px'
      }
    }
  }
})

export const useGeneralInfoSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
