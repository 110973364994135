import { toast, Bounce } from 'react-toastify'

const toastProps = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Bounce,
  theme: 'colored'
}

export const renderSuccessToast = (text) => {
  toast.success(text, toastProps)
}

export const renderErrorToast = (text) => {
  toast.error(text, toastProps)
}
