import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    locationDotIcon: {
      color: palette.blue['300'],
      width: '12px',
      height: '16px'
    },
    addressContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    address: {
      fontSize: '16px',
      color: palette.blue['300'],
      padding: 0,
      letterSpacing: 0,
      [breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    },
    distanceContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    distance: {
      fontSize: '16px',
      color: palette.text.disabled,
      padding: 0,
      letterSpacing: 0,
      [breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    },
    locationArrowIcon: {
      color: palette.text.disabled,
      width: '14px',
      height: '16px'
    }
  }
})

export const useLocationSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
