import { Box } from '@material-ui/core'

import { DescriptionSection, ExperienceLocationSection } from './components'
import { useDetailedInfoSectionStyles } from './hooks'

export const DetailedInfoSection = ({
  experience,
  address,
  distance,
  descriptionTitle,
  descriptionText,
  type
}) => {
  const { classes } = useDetailedInfoSectionStyles()

  return (
    <Box className={classes.container}>
      <ExperienceLocationSection experience={experience} address={address} distance={distance} />
      <DescriptionSection
        descriptionTitle={descriptionTitle}
        descriptionText={descriptionText}
        type={type}
      />
    </Box>
  )
}
