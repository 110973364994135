import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      backgroundColor: palette.text.disabled
    }
  }
})

export const useCircleIconStyles = () => {
  const classes = useStyles()
  return { classes }
}
