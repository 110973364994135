import moment from 'moment'

const getCurrentTimeInMinutes = () => {
  const now = new Date()
  return now.getHours() * 60 + now.getMinutes()
}

const getDayOfWeekIndex = () => {
  const now = new Date()
  return now.getDay()
}

const getTimeInMinutes = (time) => {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

const getNextWorkingDay = (schedule, currentIndex) => {
  for (let i = 1; i <= 7; i++) {
    const nextIndex = (currentIndex + i) % 7
    if (schedule[nextIndex].from && schedule[nextIndex].to && schedule[nextIndex].seats > 0) {
      return { ...schedule[nextIndex], dayOffset: i }
    }
  }
  return null // In case there's no working day found within the week
}

export const getStatusMessage = (schedule) => {
  const currentIndex = getDayOfWeekIndex()
  const currentDay = schedule[currentIndex]
  const currentTime = getCurrentTimeInMinutes()

  if (currentDay.from && currentDay.to && currentDay.seats > 0) {
    const startTime = getTimeInMinutes(currentDay.from)
    const endTime = getTimeInMinutes(currentDay.to)

    if (startTime < endTime) {
      // Normal case: start time is before end time
      if (currentTime >= startTime && currentTime < endTime) {
        return `Open until ${moment(currentDay.to, 'HH:mm').format('h:mm a')}`
      }
    } else {
      // Overnight case: end time is after midnight
      if (currentTime >= startTime || currentTime < endTime) {
        return `Open until ${moment(currentDay.to, 'HH:mm').format('h:mm a')}`
      }
    }
  }

  const nextWorkingDay = getNextWorkingDay(schedule, currentIndex)
  if (nextWorkingDay) {
    const nextDay = moment().add(nextWorkingDay.dayOffset, 'days')
    return `Opens ${nextDay.format('dddd')} at ${moment(nextWorkingDay.from, 'HH:mm').format('h:mm a')}`
  }

  return 'Closed for the week'
}
