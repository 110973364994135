import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      flexWrap: 'wrap',
      [breakpoints.down('sm')]: {
        overflowX: 'auto',
        flexWrap: 'nowrap',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none'
      }
    },
    chip: {
      flex: '0 0 auto',
      fontSize: '13px',
      '&:hover': {
        borderColor: palette.primary.main
      }
    },
    showMoreButton: {
      width: '92px',
      height: '32px',
      fontSize: '13px',
      color: palette.primary.main,
      letterSpacing: 0,
      padding: 0,
      '&:hover': {
        backgroundColor: palette.primary.light
      },
      [breakpoints.down('sm')]: {
        fontSize: '12px',
        whiteSpace: 'wrap',
        lineHeight: '12px'
      }
    }
  }
})

export const useTreatmentChipsSectionStyles = () => {
  const classes = useStyles()
  return { classes }
}
