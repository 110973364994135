import { Grid } from '@material-ui/core'

import { ExperienceSection, LocationSection } from './components'

export const ExperienceLocationSection = ({ experience, address, distance }) => {
  return (
    <Grid container spacing={1}>
      {experience && (
        <Grid item>
          <ExperienceSection experience={experience} />
        </Grid>
      )}
      <Grid item>
        <LocationSection address={address} distance={distance} />
      </Grid>
    </Grid>
  )
}
